import { CSSProperties } from 'styled-components';

const PoweredByHeadoutNoBorder = ({ style }: { style?: CSSProperties }) => {
  return (
    <svg
      width="99"
      height="32"
      viewBox="0 0 99 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_10301_63893)">
        <path
          d="M35.28 21.0697C36.26 20.2797 37.54 19.6897 39.11 19.6897C40.49 19.6897 41.67 20.2797 41.67 22.4397V29.8097H39.8V23.0297C39.8 21.8497 39.21 21.5597 38.33 21.5597C37.25 21.5597 36.27 22.1497 35.48 22.5397V30.0097H33.61V16.7397H34.69C35.38 16.7397 35.48 16.8397 35.48 17.9197L35.28 21.0697Z"
          fill="#8000FF"
        />
        <path
          d="M44.91 25.1C45.01 27.36 45.99 28.25 47.86 28.25C49.04 28.25 50.02 27.86 50.61 27.27H51.2V28.65C50.51 29.24 49.43 29.63 47.66 29.63C44.71 29.63 43.04 27.76 43.04 24.72C43.04 21.68 44.91 19.71 47.56 19.71C50.21 19.71 51.59 21.18 51.59 24.43V25.12H44.91V25.1ZM44.91 23.82H49.53C49.53 21.76 48.74 20.97 47.37 20.97C46.09 21.07 45.11 21.76 44.91 23.82Z"
          fill="#8000FF"
        />
        <path
          d="M58.47 28.54C57.78 29.33 56.8 29.72 55.62 29.72C53.85 29.72 52.47 28.74 52.47 26.87C52.47 24.81 54.14 23.92 56.3 23.92C57.09 23.92 57.77 24.02 58.46 24.21V23.03C58.46 21.95 57.77 21.36 56.59 21.36C55.41 21.36 54.53 21.75 53.84 22.15H53.25V20.77C54.04 20.38 55.12 19.98 56.79 19.98C59.05 19.98 60.43 20.77 60.43 22.93V29.81H59.64C58.85 29.61 58.56 29.32 58.46 28.53L58.47 28.54ZM58.47 27.26V25.2C57.88 25.1 57.39 25.1 56.7 25.1C55.32 25.1 54.44 25.69 54.44 26.77C54.44 27.95 55.23 28.54 56.21 28.54C57.19 28.34 57.88 27.85 58.47 27.26Z"
          fill="#8000FF"
        />
        <path
          d="M68.79 28.2497C68.1 29.0397 67.12 29.7197 65.84 29.7197C63.78 29.7197 62.2 28.2497 62.2 24.8097C62.2 20.9797 64.26 19.6997 66.13 19.6997C67.51 19.6997 68.19 20.0897 68.88 20.6797V16.4497H69.96C70.65 16.4497 70.75 16.5497 70.75 17.6297V29.4197H69.57C68.98 29.6197 68.88 29.3197 68.78 28.2397L68.79 28.2497ZM68.79 27.0697V22.0597C68.2 21.4697 67.61 21.2697 66.63 21.2697C65.45 21.2697 64.07 21.9597 64.07 24.8097C64.07 27.6597 65.05 28.3497 66.33 28.3497C67.21 28.2497 68.1 27.8597 68.79 27.0697Z"
          fill="#8000FF"
        />
        <path
          d="M72.43 24.7097C72.43 21.5597 74.49 19.6997 77.05 19.6997C79.61 19.6997 81.67 21.5697 81.67 24.7097C81.67 27.8497 79.61 29.6197 77.05 29.6197C74.49 29.7197 72.43 27.8497 72.43 24.7097ZM79.7 24.7097C79.7 22.4497 78.62 21.1697 76.95 21.1697C75.28 21.1697 74.2 22.3497 74.2 24.7097C74.2 27.0697 75.28 28.2497 76.95 28.2497C78.62 28.2497 79.7 27.0697 79.7 24.7097Z"
          fill="#8000FF"
        />
        <path
          d="M89.63 27.2599V19.7899H91.5V29.5199H90.32C89.93 29.5199 89.63 29.4199 89.63 28.4399C88.65 29.1299 87.37 29.6199 86.09 29.6199C84.62 29.6199 83.34 28.9299 83.34 27.0599V19.6899H85.21V26.5699C85.21 27.7499 85.9 28.0399 86.68 28.0399C87.86 28.1399 88.84 27.7499 89.63 27.2499V27.2599Z"
          fill="#8000FF"
        />
        <path
          d="M93.07 19.5996H94.25V16.8496H95.33C95.92 16.8496 96.12 17.1396 96.12 17.9296V19.5996H98.28V20.9796H96.12V26.7796C96.12 27.8596 96.51 28.1596 97.5 28.1596H98.29V29.3396C97.9 29.5396 97.5 29.5396 96.82 29.5396C95.25 29.5396 94.26 28.8496 94.26 26.8896V20.8896H93.08V19.6096L93.07 19.5996Z"
          fill="#8000FF"
        />
        <path
          d="M27.61 22.2798C25.46 26.0098 20.5 28.0098 15.33 27.9798C11.18 27.9598 7.14 27.3298 3.6 25.7898C12.1 26.7498 20.19 26.4898 27.6 22.2698L27.61 22.2798ZM27.61 20.7598C25.08 20.0398 22.86 19.7198 20.63 19.7098C14.76 19.6798 8.88 21.4698 3.61 23.6698C7.78 20.0498 13.97 17.2498 20.14 17.2798C23.17 17.4998 26.51 18.8298 27.61 20.7598ZM28.72 21.5798C28.74 17.6298 22.88 15.9798 19.54 15.9598C14.28 15.9298 9.71 17.8298 5.96 19.9398C5.66 19.5298 5.36 19.3298 4.85 19.3298C4.04 19.3298 3.64 19.2198 3.03 19.2198C2.32 19.2198 1.31 19.2198 0.699997 19.3098C0.299997 19.3098 0.0899968 19.7098 0.289997 20.1198C0.489997 21.4398 1.09 22.5498 1.79 23.5698C1.48 24.5798 1.58 25.4898 2.48 26.4098C2.17 27.5198 2.06 28.9398 2.16 30.0498C2.16 30.4498 2.46 30.6598 2.87 30.6598C4.39 30.3598 5.81 29.9698 7.03 29.2698C7.33 29.1698 7.44 28.8698 7.64 28.6698L11.28 29.2998C11.88 29.9098 12.39 30.4198 13.09 31.0298C13.49 31.3398 14.1 31.6398 14.6 31.6498C17.23 31.6598 19.46 31.2698 21.49 30.5698C22.2 30.2698 22.71 29.3598 23.63 27.9498C25.26 26.8498 28.71 25.0398 28.72 21.5998V21.5798Z"
          fill="#8000FF"
        />
        <path
          d="M1.32 8.34V9.29H0V0H1.32V3.73C1.77 3.06 2.5 2.66 3.41 2.66C5.08 2.66 6.33 3.91 6.33 6C6.33 8.11 5.09 9.43 3.4 9.43C2.51 9.43 1.78 9.02 1.32 8.34ZM4.91 6.06V5.96C4.91 4.57 4.19 3.81 3.08 3.81C1.97 3.81 1.25 4.72 1.25 6.01V6.12C1.25 7.4 1.92 8.27 3.08 8.27C4.17 8.27 4.91 7.46 4.91 6.05V6.06Z"
          fill="#444444"
        />
        <path
          d="M6.76001 10.68H7.38001C8.27001 10.68 8.48001 10.49 8.99001 9.17004L6.49001 2.79004H7.91001L9.64001 7.63004L11.22 2.79004H12.65L10.16 9.75004C9.57001 11.29 9.00001 11.9 7.45001 11.9H6.76001V10.67V10.68Z"
          fill="#444444"
        />
      </g>
      <defs>
        <clipPath id="clip0_10301_63893">
          <rect width="98.28" height="31.62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PoweredByHeadoutNoBorder;
